import NavigableModal, {
	MODAL_TYPES,
	closeNavigableModal,
} from '@components/NavigableModal/NavigableModal';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { ModalPortal } from '@xo-union/ui-accessibility';
import React, { useCallback } from 'react';
import { FCWithChildren } from 'types/react-extended';
import { useInlineRfqForm } from '../../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { ContentWrapper } from '../content_wrapper';
import { FullScreenLandscapeWrapper } from '../full_screen_landscape_wrapper';
import type { ModalSourceContent } from '../types';
import Styles from './ModalWrapper.scss';

export interface ModalWrapperProps {
	hash?: string;
	isModalOpen: boolean;
	closeModal: () => void;
	mediaLength: number;
	vendorName: Vendor.Decorated['name'];
	sourceContent: ModalSourceContent;
	closeOverviewModal?: () => void;
	isLandscape?: boolean;
	isQuickLinkClick?: boolean;
	isModalOnModal?: boolean;
	mediaType?: Vendor.Media['mediaType'];
	context?: ReturnType<typeof useInlineRfqForm>;
}

const ModalWrapper: FCWithChildren<ModalWrapperProps> = ({
	isLandscape,
	children,
	hash = 'gallery-modal',
	isModalOpen,
	closeModal,
	isModalOnModal = false,
	...sharedProps
}) => {
	const [, , windowHeight] = useScreenDetails();
	const asideStyle = {
		'--window-height': `${windowHeight}px`,
	} as React.CSSProperties;

	const childComponentCloseModal = useCallback(() => {
		closeNavigableModal(closeModal, hash);
	}, [closeModal, hash]);

	return (
		<ModalPortal>
			<aside style={asideStyle} className={Styles.modalWrapperContainer}>
				<NavigableModal
					hash={hash}
					isModalOpen={isModalOpen}
					closeCallback={closeModal}
					type={MODAL_TYPES.CONTAINER}
					isModalOnModal={isModalOnModal}
				>
					{isLandscape ? (
						<FullScreenLandscapeWrapper closeModal={childComponentCloseModal}>
							{children}
						</FullScreenLandscapeWrapper>
					) : (
						<ContentWrapper
							closeModal={childComponentCloseModal}
							{...sharedProps}
						>
							{children}
						</ContentWrapper>
					)}
				</NavigableModal>
			</aside>
		</ModalPortal>
	);
};

export { ModalWrapper };
