import React from 'react';
import { FCWithChildren } from 'types/react-extended';
import { useInlineRfqForm } from '../../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import Footer from '../footer/footer';
import Header from '../header/header';
import type { ModalSourceContent } from '../types';

export interface ContentWrapperProps {
	closeModal: () => void;
	mediaLength: number;
	sourceContent: ModalSourceContent;
	vendorName: Vendor.Decorated['name'];
	isQuickLinkClick?: boolean;
	mediaType?: Vendor.Media['mediaType'];
	closeOverviewModal?: () => void;
	context?: ReturnType<typeof useInlineRfqForm>;
}

const ContentWrapper: FCWithChildren<ContentWrapperProps> = (props) => {
	const {
		closeModal,
		closeOverviewModal,
		mediaLength,
		sourceContent,
		vendorName,
		children,
		isQuickLinkClick = false,
		mediaType,
		context,
	} = props;
	return (
		<>
			<Header
				vendorName={vendorName}
				closeModal={closeModal}
				isQuickLinkClick={isQuickLinkClick}
				mediaType={mediaType}
				mediaLength={mediaLength}
			/>
			{children}
			<Footer
				sourceContent={sourceContent}
				closeModal={closeModal}
				closeOverviewModal={closeOverviewModal}
				context={context}
			/>
		</>
	);
};

export { ContentWrapper };
